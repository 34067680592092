import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-baton-rouge-louisiana.png'
import image0 from "../../images/cities/scale-model-of-uss-kidd-museum-in-baton-rouge-louisiana.png"
import image1 from "../../images/cities/scale-model-of-louisiana's-old-state-capitol-in-baton-rouge-louisiana.png"
import image2 from "../../images/cities/scale-model-of-old-governor's-mansion-in-baton-rouge-louisiana.png"
import image3 from "../../images/cities/scale-model-of-capitol-park-museum-in-baton-rouge-louisiana.png"
import image4 from "../../images/cities/scale-model-of-botanic-gardens-in-baton-rouge-louisiana.png"
import image5 from "../../images/cities/scale-model-of-spanish-town-historic-district-in-baton-rouge-louisiana.png"
import image6 from "../../images/cities/scale-model-of-visit-baton-rouge-in-baton-rouge-louisiana.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Baton Rouge'
            state='Louisiana'
            image={image}
            lat='30.4514677'
            lon='-91.1871466'
            attractions={ [{"name": "USS Kidd Museum", "vicinity": "305 S River Rd, Baton Rouge", "types": ["museum", "point_of_interest", "establishment"], "lat": 30.4433678, "lng": -91.19025239999996}, {"name": "Louisiana's Old State Capitol", "vicinity": "100 North Blvd, Baton Rouge", "types": ["museum", "point_of_interest", "establishment"], "lat": 30.446596, "lng": -91.189033}, {"name": "Old Governor's Mansion", "vicinity": "502 North Blvd, Baton Rouge", "types": ["point_of_interest", "establishment"], "lat": 30.4465263, "lng": -91.18502469999999}, {"name": "Capitol Park Museum", "vicinity": "660 N 4th St, Baton Rouge", "types": ["museum", "point_of_interest", "establishment"], "lat": 30.453978, "lng": -91.1864564}, {"name": "Botanic Gardens", "vicinity": "7950 Independence Blvd, Baton Rouge", "types": ["park", "point_of_interest", "establishment"], "lat": 30.4443711, "lng": -91.10667539999997}, {"name": "Spanish Town Historic District", "vicinity": "Baton Rouge", "types": ["point_of_interest", "establishment"], "lat": 30.4547412, "lng": -91.1835428}, {"name": "Visit Baton Rouge", "vicinity": "359 3rd St # A, Baton Rouge", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 30.4502533, "lng": -91.1883838}] }
            attractionImages={ {"USS Kidd Museum":image0,"Louisiana's Old State Capitol":image1,"Old Governor's Mansion":image2,"Capitol Park Museum":image3,"Botanic Gardens":image4,"Spanish Town Historic District":image5,"Visit Baton Rouge":image6,} }
       />);
  }
}